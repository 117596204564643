import { rewriteExternalLinks } from './links/rewriteExternalLinks.js';
import { renderComments } from './comments/renderComments.js';
import { renderPosts } from './posts/renderPosts.js';
import { enableLightbox } from './lightbox/enableLightbox.js';
import { initializeHighlight } from './highlight/initializeHighlight.js';

// Attepts to render comments (post pages).
renderComments();

// Attempts to render all posts page.
renderPosts();

// Attempts to rewrite photo links to enable lightbox.
enableLightbox();

// Attempts to rewrite all external links.
rewriteExternalLinks();

// Highlight code samples.
initializeHighlight();
