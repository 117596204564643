import { CommentPublicData } from '@infdot/comments';
import React from 'react';

type Props = {
  comment: CommentPublicData;
};

/**
 * Renders site link for a comment.
 */
export const SiteLink = ({ comment }: Props) => {
  return (
    <a href={comment.site} target="_blank" rel="noreferrer nofollow">
      {comment.author}
    </a>
  );
};
