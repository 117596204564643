import React from 'react';
import { LightboxImage } from './LightboxImage.js';
import { LightboxSpinner } from './LightboxSpinner.js';
import { LightboxTitle } from './LightboxTitle.js';
import { LightboxControls } from './LightboxControls.js';
import { useLightboxContext } from './LightboxProvider.js';
import { clsx } from 'clsx';

/**
 * Renders the "inset" component which contains
 * the displayed image and the controls.
 */
export const LightboxInset = () => {
  const { lightboxType } = useLightboxContext();

  const className = clsx('inset', {
    'fullscreen': lightboxType === 'fullscreen',
    'popover': lightboxType === 'popover'
  });

  return (
    <div className={className}>
      <LightboxImage />
      <LightboxSpinner />
      <LightboxTitle />
      <LightboxControls />
    </div>
  );
};
