import React from 'react';
import { createRoot } from 'react-dom/client';
import { LIGHTBOX_ELEMENT_ID } from '@infdot/rlaanemets-shared';
import { extractPostData } from '../posts/extractPostData.js';
import { extractPhotos } from './extractPhotos.js';
import { Lightbox } from './Lightbox.js';

/**
 * Rewrites photo links to open in lightbox.
 */
export const enableLightbox = () => {
  const photos = extractPhotos();

  if (photos.length === 0) {
    // No photos to show.
    return;
  }

  const postData = extractPostData();
  if (!postData) {
    // Post metadata is missing.
    return;
  }

  const lightboxElement = document.getElementById(LIGHTBOX_ELEMENT_ID);
  if (!lightboxElement) {
    // No placeholder element for the lightbox.
    return;
  }

  photos.forEach((photo, index) => {
    const anchorElement = photo.element.querySelector('a');
    if (anchorElement) {
      anchorElement.addEventListener('click', (event) => {
        event.preventDefault();
        window.location.hash = `lightbox/${index}`;
      });
    }
  });

  // Creates new React root and enables the lightbox on it.
  const root = createRoot(lightboxElement);
  root.render(<Lightbox photos={photos} postTitle={postData.title} />);
};
