import React from 'react';
import { useLightboxContext } from './LightboxProvider.js';

/**
 * Renders "modal" layer between the lightbox components
 * and the rest of the page.
 */
export const LightboxLayer = () => {
  const { lightboxType, handleClose } = useLightboxContext();

  if (lightboxType === 'fullscreen') {
    // The layer is not used for fullscreen display since
    // there is no space around it where the underlying page
    // is visible.
    return null;
  }

  return <div className="layer" onClick={handleClose} />;
};
