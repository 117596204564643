import { PostData } from '@infdot/blog-data';
import { POST_DATA_ELEMENT_ID } from '@infdot/rlaanemets-shared';

/**
 * Extracts metadata of the current entry.
 */
export const extractPostData = () => {
  const postDataElement = document.getElementById(POST_DATA_ELEMENT_ID);
  if (!postDataElement) {
    return;
  }
  const postDataJson = postDataElement.textContent;
  if (!postDataJson) {
    return;
  }
  return JSON.parse(postDataJson) as PostData;
};
